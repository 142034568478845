<template>
    <ul v-if="this.nav_style === 'primary'" class="c-nav__items o-list-unstyled" :class="!is_drawer && 'u-show-for-lg'">
        <li v-for="nav_link in this.nav_links" class="c-nav__item" :key="nav_link.slug" :class="[is_drawer && 'c-nav__item--drawer', getActiveLink(nav_link)]">
            <inertia-link @click="closeNavigationDrawer" :href="getActiveHref($page.props.locale, nav_link.slug)" class="c-nav__anchor">
                {{ nav_link.title }}
            </inertia-link>
        </li>
        <li class="c-nav__item" :class="is_drawer && 'c-nav__item--drawer'">
            <a :href="translate('js.frontend.header.external_link')" target="_blank" rel="noopener" class="c-nav__anchor">{{ translate('js.frontend.header.external_link_text') }}</a>
        </li>
    </ul>
    <ul v-if="this.nav_style === 'secondary'" class="c-nav__items c-nav__items--secondary o-list-unstyled o-flex o-flex--middle">
        <li class="c-nav__item c-dropdown">
            <v-select :options="languages" v-model="selected_language" @update:modelValue="localeChangeUrl(selected_language)" :clearable="false" :searchable="false" />
        </li>
        <template v-if="isLoggedIn">
            <li class="c-nav__item u-show-for-lg">
                <inertia-link :href="`/${$page.props.locale}/account/dashboard`" :class="'c-button c-button--solid o-flex--wrap'">
                    {{ translate('js.frontend.account.my_account') }} <span class="c-button__icon user-icon"></span>
                </inertia-link>
            </li>
            <li class="c-nav__item u-hide-for-lg">
                <inertia-link :href="`/${$page.props.locale}/account/dashboard`" :class="'c-button c-button--solid'">
                    <span class="c-button__icon user-icon u-no-margin-left"></span>
                </inertia-link>
            </li>
        </template>
        <template v-else>
            <li class="c-nav__item u-show-for-lg">
                <inertia-link :href="`/${$page.props.locale}/account/login`" :class="'c-button c-button--solid o-flex--wrap'">
                    {{ translate('js.frontend.account.login') }} <span class="c-button__icon user-icon"></span>
                </inertia-link>
            </li>
            <li class="c-nav__item u-hide-for-lg">
                <inertia-link :href="`/${$page.props.locale}/account/login`" :class="'c-button c-button--solid'">
                    <span class="c-button__icon user-icon u-no-margin-left"></span>
                </inertia-link>
            </li>
        </template>
        <li class="c-nav__item">
            <div class="u-relative">
                <inertia-link :href="`/${$page.props.locale}/checkout/shopping-cart`" :class="'c-nav__shopping-cart'">
                    <span class="c-nav__shopping-cart-icon"></span>
                    <span v-if="$page.props.shopping_cart_count > 0" class="c-nav__counter">{{ $page.props.shopping_cart_count }}</span>
                </inertia-link>
            </div>
        </li>
    </ul>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';
    import vSelect from 'vue-select';

    export default {
        props: {
            nav_style : String,
            is_drawer : Boolean
        },
        components: {
            'inertia-link' : Link,
            'v-select' : vSelect
        },
        computed: {
            isLoggedIn: function() {
                return usePage().props.is_logged_in;
            },
        },
        data: () => {
            return {
                nav_links: {},
                languages: ['NL', 'EN'],
                selected_language: ''
            }
        },
        mounted() {
            this.selected_language = usePage().props.locale.toUpperCase();
            this.nav_links = usePage().props.nav_links;
        },
        methods: {
            /**
             * Close navigation drawer.
             *
             * @author Derk Jan Speelman <derkjan@click.nl>
             * @return void
             */
            closeNavigationDrawer: () => {
                window.dispatchEvent(new CustomEvent('closeNavigationDrawer'));
            },
            /**
             * Change url to the desired locale
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param   string  locale
             * @return void
             */
            localeChangeUrl : function(value) {
                let locale = value.toLowerCase();
                axios.get(`/api/change-locale?locale=${locale}`)
                    .then((response) => {
                        window.location.href = window.location.origin + '/' + locale + response.data;
                    });
            },
            /**
             * Get active link
             *
             * @author Lennart van Welzen <lennart@click.nl>
             * @param   string  link
             * @return string
             */
            getActiveLink : function(link) {
                if(this.$page.url === `/${this.$page.props.locale}` && link.slug === '') {
                    return 'c-nav__item--active';
                } else if(this.$page.url.startsWith(`/${this.$page.props.locale}/${link.slug}`) && link.slug) {
                    return 'c-nav__item--active';
                } else {
                    return false;
                }
            },
            /**
             * Get active href
             *
             * @author Lennart van Welzen <lennart@click.nl>
             * @param   string  link
             * @return string
             */
            getActiveHref(locale, link) {
                if(link === ''){
                    return `/${locale}`;
                } else {
                    return `/${locale}/${link}`;
                }
            }
        }
    }
</script>
