<template>
    <inertia-head>
        <title>{{ documentUrl }}</title>
        <meta name="description" :content="metaDescription"/>

        <!-- Open Graph, for sharing on Social Media platforms -->
        <meta property="og:title" :content="documentUrl">
        <meta property="og:description" :content="metaDescription">
        <meta property="og:type" content="website">
        <meta property="og:url" :content="getCurrentUrl">
        <meta property="og:image" :content="getOpengraphImageUrl">
        <meta property="og:image:width" content="1200">
        <meta property="og:image:height" content="630">

        <!-- Twitter card, for sharing on Twitter -->
        <meta name="twitter:card" content="summary">
        <meta name="twitter:site" content="@9292">
        <meta name="twitter:title" :content="documentUrl">
        <meta name="twitter:description" :content="metaDescription">
        <meta name="twitter:image" :content="getOpengraphImageUrl">
        <meta name="twitter:url" :content="getCurrentUrl">
    </inertia-head>
    <slot/>
    <!--<cookie-consent/>-->
</template>

<script>
    import { Head, usePage } from '@inertiajs/vue3';
    // RL: Cookie consent temporarily disabled after consultation between Bart and Dennis
    // Cookie consent not needed for now because GTM carries data through anonymously
    // import Cookie from '../Components/UI/Cookie';

    export default {
        data() {
            return {
                current_url: ''
            }
        },
        components: {
            'inertia-head' : Head,
            //'cookie-consent' : Cookie
        },
        computed: {
            /**
             * Sets meta description based on set meta_title
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return {string} widget_url
             */
            documentUrl() {
                if( usePage().props.meta_data && usePage().props.meta_data.title ){
                    return usePage().props.meta_data.title + Lang.get('js.frontend.page_title.brand');
                }else{
                    return Lang.get('js.frontend.page_title.default');
                }
            },
            /**
             * Sets meta description based on set meta_title
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return {string} widget_url
             */
            metaDescription(){
                if( usePage().props.meta_data && usePage().props.meta_data.description ){
                    return usePage().props.meta_data.description;
                }else{
                    return Lang.get('js.frontend.meta.description');
                }
            },
            /**
             * Gets url of opengraph image
             *
             * @author Lennart van Welzen <lennart@click.nl>
             * @return {string}
             */
            getCurrentUrl(){
                let url = window.location.origin + usePage().url;
                return url;
            },
            /**
             * Gets url of opengraph image
             *
             * @author Lennart van Welzen <lennart@click.nl>
             * @author Robin van Leeuwen <robin@click.nl>
             * @return {string}
             */
            getOpengraphImageUrl() {
                let url = null;
                if( usePage().props.meta_data && usePage().props.meta_data.image ){
                    url = usePage().props.meta_data.image;
                } else {
                    url = window.location.origin + '/images/favicon/opengraph.png';
                }
                return url;
            }
        }
    }
</script>