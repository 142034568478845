/**
 * Sets breakpoints
 *
 * @author Lennart van Welzen <lennart@click.nl>
 */
window.setBreakpoints = () => {
    // Create meta element with breakpoints stored in font-family
    let meta_bp = document.createElement('meta');
    meta_bp.classList.add('bp');
    meta_bp.name = 'bp';
    meta_bp.setAttribute('id', 'bp');
    meta_bp.setAttribute('content', '');
    document.getElementsByTagName('head')[0].appendChild(meta_bp);

    // Get breakpoints, create query and sets global breakpoints object
    let element = document.getElementById('bp');
    let raw_query = getComputedStyle(element).getPropertyValue('font-family');
    let query = raw_query.replace(/^[\/\\'"]+|(;\s?})+|[\/\\'"]+$/g, '')
    let split = query.split(/&| /) 
    window.breakpoints = {};

    // Fill breakpoint object with the correct sizes and values
    for(let i = 0; i < split.length; i++){
        let key = split[i].split('=', 1);
        const value = split[i].slice(split[i].indexOf('(') + 1, split[i].lastIndexOf('px)'));
        breakpoints[key] = value;
    }
}

/**
 * Gets given breakpoint value
 *
 * @author Lennart van Welzen <lennart@click.nl>
 */
window.getBreakpoint = (size) => {
    return breakpoints[size];
}