<template>
    <ul class="c-content-block__items o-list-unstyled o-flex o-flex--wrap u-margin-top-small">
        <li class="c-radio" v-for="(timeslot_info, key) in timeslots" :key="key">
            <input @input="updateTime" type="radio" :id="timeslot_info.timeslot" name="time_select" class="c-radio__inner" :value="timeslot_info.timeslot" v-model="selected_timeslot" :disabled="!timeslot_info.available">
            <label :for="timeslot_info.timeslot" class="c-radio__label u-font-weight-bold" :class="!timeslot_info.available && 'c-radio__label--disabled'">{{ timeslot_info.timeslot }}</label>
        </li>
    </ul>
</template>

<script>
    export default {
        props: ['modelValue', 'timeslots'],
        data() {
            return {
                selected_timeslot: null
            }
        },
        watch: {
            timeslots: {
                handler(newValue, oldValue) {
                    this.selected_timeslot = null;
                },
                deep:true
            }
        },
        methods: {
            /**
             * Pass value to parent ($emit)
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
            updateTime(event) {
                this.$emit('update:modelValue', event.target.value);
            },
        }
    }
</script>
