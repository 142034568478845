<template>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--sm-12 o-col--md-10">
            <span class="c-heading--large u-no-margin-bottom u-font-weight-bold u-color-primary">
                {{ translate(`js.frontend.error.${this.status}_code`) }}
            </span>
            <h2 class="c-heading--large u-no-margin-bottom u-no-margin-top">
                {{ translate(`js.frontend.error.${this.status}_title`) }}
            </h2>
            <p>{{ translate(`js.frontend.error.${this.status}_content`) }}</p>
            <inertia-link v-if="status != 503" :href="`/${$page.props.locale}/`" class="c-button c-button--solid">
                {{ translate('js.frontend.error.back_to_home') }} <span class="c-button__icon far fa-arrow-right"></span>
            </inertia-link>
        </div>
    </div>
</template>

<script>
    import { Link } from '@inertiajs/vue3';

    export default {
        components: {
            'inertia-link' : Link
        },
        props: {
            status: Number,
        }
    }
</script>