<template>
    <div v-if="errors && Object.keys(errors).length > 0" class="c-alert c-alert--alert c-alert--radius" ref="error">
        <template v-if="status === 422 && errors_array == null">
            {{ translate('js.frontend.global.validation_errors') }}
        </template>
        <template v-if="errors_array">
            <template v-for="error in errors_array">
                {{ error }}
            </template>
        </template>
        <template v-if="errors_string">
            {{ errors_string }}
        </template>
    </div>
</template>
<script>
    export default {
        props: ['errors'],
        watch: {
            /**
             * Watch if any errors come in, split the response based on status
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  value 
             * @return void
             */
            'errors': function(value) 
            {
                // if value is not null
                if(value) {
                    this.setErrors();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                // reset everything when value is null
                } else {
                    this.errors_array = null;
                    this.errors_string = null;
                    this.status = 200;
                }
            }
        },
        data: function()
        {
            return {
                status: 200,
                errors_array: null,
                errors_string: null,
            }
        },
        methods: {
            /**
             * Set the errors array or string depending on the status code
             *
             * @author Joost Ligthart <joost@click.nl>
             */
            setErrors: function()
            {
                this.status = this.errors.status;
                // if the status code is 422 (validation error)
                if(this.errors.status === 422) {
                    // only get the global errors, because other fields will be shown under the field itself
                    if(this.errors.data.errors.global) {
                        this.errors_array = this.errors.data.errors.global;
                    }
                } else {
                    if(typeof this.errors == 'string'){
                        this.errors_string = this.errors;
                    } else {
                        // fallback when 4xx or 5xx error appears
                        this.errors_string = Lang.get('js.frontend.global.something_went_wrong');
                    }
                }
            }
        }
    }
</script>
