<template>
    <div class="u-padding-vertical-large u-bg-gradient u-color-white">
        <div v-if="ticketSelectTitle || product.ticket_select_description" class="o-row o-flex--center o-flex--middle">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <div :class="'u-text-left'">
                    <h1 v-if="is_first_heading_tag" class="u-no-margin-top u-margin-bottom-small u-break-word c-heading--large">{{ ticketSelectTitle }}</h1>
                    <h2 v-if="!is_first_heading_tag" class="u-no-margin-top u-margin-bottom-small u-break-word c-heading--large">{{ ticketSelectTitle }}</h2>
                    <div v-if="product.ticket_select_description" class="u-margin-bottom-small">{{ product.ticket_select_description }}</div>
                </div>
            </div>
        </div>
        <div class="o-row o-flex--center o-flex--middle">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <ticket-select v-if="product" :product="product"/>
            </div>
        </div>
    </div>
</template>

<script>
    import TicketSelect from '../Components/TicketSelect';

    export default {
        props: {
            product: Object,
            is_first_heading_tag: Boolean
        },
        components: {
            'ticket-select' : TicketSelect
        },
        computed: {
            /**
             * Returns the Ticket select title
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return string
             */
            ticketSelectTitle: function() {
                if(this.product.ticket_select_title){
                    return this.product.ticket_select_title;
                }else if(this.product.title){
                    return Lang.get('js.frontend.product.ticket_select.default_title', {product_title: this.product.title} );
                }else{
                    return '';
                }
            },
        }
    }
</script>