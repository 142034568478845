<template>
    <section :class="is_first_section ? 'u-margin-top-xlarge u-margin-bottom-large' : 'u-margin-vertical-large'">
        <div class="o-row o-flex--center o-flex--middle" :class="data.text_alignment === 'left' && 'o-row--reverse'">
            <div class="o-col o-col--md-12 o-col--lg-5">
                <image-wrapper :image_object="data.image_object" :title="data.title ? data.title : null" :is_with_text_content="true"/>
            </div>
            <div class="o-col o-col--md-12 o-col--lg-5">
                <text-content :item="data" :button_type="'secondary'"/>
            </div>
        </div>
    </section>
</template>
<script>
    import Image from '../Components/Image.vue';
    import TextContent from '../Components/TextContent.vue';

    export default {
        props: {
            data : Object,
            is_first_section: Boolean
        },
        components: {
            'image-wrapper' : Image,
            'text-content' : TextContent
        }
    }
</script>