<template>
    <div v-if="is_background_image" :class="class_name" :style="{ backgroundImage: 'url(' + current_image + ')' }">
        <slot/>
    </div>
    <img v-else :src="current_image" :alt="alt_text" :class="class_name">
</template>

<script>
    export default {
        props: {
            is_background_image: Boolean,
            class_name: String,
            alt_text: String,
            key: Number,
            small: String,
            medium: String,
            large: String
        },
        data() {
            return {
                current_image: '',
            }
        },
        mounted() {
            this.setImage()
            window.addEventListener('resize', this.setImage, { passive: true });
        },
        methods: {
            /**
             * Sets correct image
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
             setImage() {
                let window_width = window.innerWidth;
                if (window_width < getBreakpoint('sm')) {
                    this.current_image = this.small
                } else if (window_width > getBreakpoint('sm') && window_width < getBreakpoint('md')) {
                    this.current_image = this.medium
                } else {
                    this.current_image = this.large
                }
            }
        }
    }
</script>