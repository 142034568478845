<template>
    <section :class="is_first_section ? 'u-margin-top-xlarge u-margin-bottom-large' : 'u-margin-vertical-large'">
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <image-wrapper :image_object="data.image_object" :title="data.title ? data.title : null"/>
            </div>
        </div>
    </section>
</template>
<script>
    import Image from '../Components/Image.vue';

    export default {
        props: {
            data : Object,
            is_first_section: Boolean
        },
        components: {
            'image-wrapper' : Image
        }
    }
</script>