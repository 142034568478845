<template>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--md-12 o-col--lg-10">
            <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.checkout.shopping_cart.shopping_cart') }}</h1>
            <div class="u-margin-top-xsmall u-margin-bottom-medium u-color-alert u-font-weight-bold" v-if="error_string || validation_errors.length > 0">
                <p v-if="validation_errors.length > 0">
                    <template v-for="(error, key) in validation_errors" :key="key">
                        {{ error }}
                    </template>
                </p>
                <p v-if="error_string">
                    {{ error_string }}
                </p>
            </div>
            <div v-if="is_loading">
                <shopping-cart-item :is_loading="is_loading"></shopping-cart-item>
            </div>
            <div v-else-if="!is_loading && products.length > 0">
                <shopping-cart-item v-for="(product, key) in products" @amountUpdated="shoppingCartValidation" @updateProducts="updateProducts" :key="key" :product="product" :is_loading="is_loading"></shopping-cart-item>
                <div class="c-panel u-no-padding-top o-flex o-flex--justify u-font-medium">
                    <span class="u-font-weight-bold">{{ translate('js.frontend.checkout.shopping_cart.total') }}</span>
                    <span class="u-font-weight-bold">{{ formatPrice(totalPrice) }}</span>
                </div>
            </div>
            <div v-else class="u-margin-vertical-large">
                <span>{{ translate('js.frontend.checkout.shopping_cart.empty_cart') }}</span>
            </div>
            <div class="o-flex o-flex--justify">
                <inertia-link :href="$page.props.previous_url_path" :class="'c-button c-button--hollow c-button--secondary'">
                    <span class="c-button__icon--left far fa-arrow-left"></span>{{ translate('js.frontend.checkout.shopping_cart.continue_shopping') }}
                </inertia-link>
                <inertia-link :href="`/${$page.props.locale}/checkout/login`" :class="'c-button c-button--solid'" :disabled="products.length == 0 || is_disabled">
                    {{ translate('js.frontend.checkout.next_step') }} <span class="c-button__icon far fa-arrow-right"></span>
                </inertia-link>
            </div>
        </div>
    </div>
</template>

<script>
    import ShoppingCartItem from '../../Components/ShoppingCartItem';
    import { Link, usePage } from '@inertiajs/vue3';

    export default {
        props: {
            error_string: String,
            validation_errors: {
                type: Array,
                default: () => []
            }
        },
        components: {
            'shopping-cart-item' : ShoppingCartItem,
            'inertia-link' : Link
        },
        data() {
            return {
                products: [],
                is_loading: true,
                is_disabled: false,
                error_array: [],
            }
        },
        mounted() {
            this.getShoppingcartContent();
        },
        computed: {
            /**
             * Calculates the total price of the ordered tickets
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return int
             */
            totalPrice() {
                let total = 0;
                this.products.forEach((product) => {
                    product.product_variants.forEach(product_article => {
                        if( product_article.price !== null ){
                            total += (parseFloat(product_article.price) * parseInt(product_article.amount));
                        }
                    });
                });
                return total;
            }
        },
        methods: {
            /**
             * Get the content to populate the shopping cart
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return void
             */
            getShoppingcartContent:  function() {
                this.is_loading = true;
                axios.get(`/${usePage().props.locale}/api/checkout/get-shopping-cart-content`)
                    .then((response) => {
                        this.products = response.data;
                        this.is_loading = false;
                    })
            },
            /**
             * Update the product array with the value from the 'update-products' event
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  value [array of products with product variants]
             * @return void
             */
            updateProducts: function(value) {
                this.products = value;
            },
            /**
             * Check if any shopping cart items are more than the max amount
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {int} key of the cart item
             * @param  {bool} is_invalid cart item
             * @return void
             */
            shoppingCartValidation: function(key, is_invalid){
                this.error_array[key] = is_invalid;
                this.is_disabled = false;

                this.error_array.forEach((invalid) => {
                    if(invalid){
                        this.is_disabled = true;
                    }
                });
            }
        }
    }
</script>