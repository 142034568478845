<template>
    <master-layout>
        <header-wrapper/>
            <slot/>
        <footer-wrapper/>
    </master-layout>
</template>

<script>
    import Header from '../Components/Header';
    import Footer from '../Components/Footer';
    import MasterLayout from '../Shared/MasterLayout';

    export default {
        components: {
            'header-wrapper' : Header,
            'footer-wrapper' : Footer,
            'master-layout' : MasterLayout
        }
    }
</script>