<template>
    <div>
        <hero :data="data" :hero_size="'large'" :is_slider="true" :is_first_heading_tag="is_first_heading_tag"/>
    </div>
</template>
<script>
    import Hero from '../Components/Hero.vue';
    
    export default {
        props: {
            data : Object,
            is_first_heading_tag : Boolean
        },
        components: {
            'hero' : Hero
        }
    }
</script>