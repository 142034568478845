<template>
    <div class="o-flex--grow u-margin-vertical-large">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <errors :errors="errors"></errors>
                <h2 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.edit_details_title') }}</h2>
            </div>
        </div>
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <form class="c-form" v-on:submit.prevent="submitForm">
                    <div class="c-form--grow">
                        <div class="c-form__group">
                            <label for="first-name">{{ translate('js.frontend.account.first_name') }}</label>
                            <input class="c-form__input" id="first-name" type="text" v-model="form.first_name" :maxLength="128">
                            <p v-if="hasError('first_name')" class="c-form__message c-form__message--error">
                                {{ getError('first_name') }}
                            </p>
                        </div>
                        <div class="c-form__group--infix">
                            <label for="infix">{{ translate('js.frontend.account.insertion') }}</label>
                            <input class="c-form__input" id="infix" type="text" v-model="form.insertion" :maxLength="128">
                            <p v-if="hasError('insertion')" class="c-form__message c-form__message--error">
                                {{ getError('insertion') }}
                            </p>
                        </div>
                        <div class="c-form__group">
                            <label for="last-name">{{ translate('js.frontend.account.last_name') }}</label>
                            <input class="c-form__input" id="last-name" type="text" v-model="form.last_name" :maxLength="128">
                            <p v-if="hasError('last_name')" class="c-form__message c-form__message--error">
                                {{ getError('last_name') }}
                            </p>
                        </div>
                    </div>
                    <div class="c-form__group">
                            <label for="phone">{{ translate('js.frontend.account.phone') }}</label>
                            <input class="c-form__input" id="phone" type="tel" v-model="form.phone" :maxLength="32">
                            <p v-if="hasError('phone')" class="c-form__message c-form__message--error">
                                {{ getError('phone') }}
                            </p>
                    </div>
                    <div class="o-flex o-flex--justify">
                        <inertia-link :href="`/${$page.props.locale}/account/dashboard`" class="c-button c-button--hollow c-button--secondary">
                            <span class="c-button__icon--left far fa-arrow-left"></span> {{ translate('js.frontend.global.go_back') }}
                        </inertia-link>
                        <div class="c-form__submit">
                            <button :disabled="disable_submit" button type="submit" class="c-button c-button--solid">
                                {{ translate('js.frontend.global.edit') }}<span class="c-button__icon far fa-arrow-right"></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';
    import Errors from '../../Components/UI/Errors';

    export default {
        components: {
            'inertia-link' : Link,
            'errors' : Errors
        },
        mounted() {
            this.getUserData();
        },
        data() {
            return {
                is_disable_submit: false,
                form: {},
                errors: null,
                success_message: null,
            }
        },
        methods: {
            /**
             * Get the data related to current user
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            getUserData:  function() {
                axios.get(`/${usePage().props.locale}/api/my-account/user/data`)
                    .then((response) => this.form = response.data)
            },
             /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm: function()
            {
                this.is_disable_submit = true;
                this.errors = null;
                this.success_message = null;
                axios.post(`/${usePage().props.locale}/api/my-account/user/update`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitSuccess: function(response)
            {
                window.location.href = response.data.route;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(errors)
            {
                this.is_disable_submit = false;

                if( errors.response.status == 422){
                    this.errors = errors.response;
                } else if( errors.response.status == 403 ) {
                    window.location.href = errors.response.data.route;
                }else{
                    this.errors = [];
                    this.errors['general'] = Lang.get('js.frontend.global.something_went_wrong');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                if(this.errors && this.errors.data){
                    return this.errors.data.errors[field][0];
                }
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors.data && this.errors.data.errors && this.errors.data.errors[field];
            },
        }
    }
</script>
