<template>
    <master-layout>
        <div class="o-flex--grow">
            <header class="o-row">
                <div class="o-col o-col--md-12 o-flex o-flex--middle o-flex--center u-margin-top-small">
                    <inertia-link :href="`/${$page.props.locale}`" :class="'c-nav__logo'">
                        <img :src="`${$page.props.cdn_url}/images/frontend/logo_client_${$page.props.locale}.svg`" :alt="translate('js.frontend.header.brand_name') + ' ' + translate('js.frontend.header.slogan')" class="u-full-width" width="98" height="40">
                    </inertia-link>
                </div>
            </header>
            <div class="o-row o-flex--center u-margin-top-small">
                <div class="o-col o-col--sm-10 o-col--md-6">
                    <div class="c-checkout-bar">
                        <ul class="c-checkout-bar__items o-list-unstyled">
                            <li v-for="(step, index) in steps" :key="index" :class="'is-' + step.state" class="c-checkout-bar__step">
                                <template v-if="step.url">
                                    <div class="c-checkout-bar__line">
                                        <inertia-link :href="step.url" :class="'c-checkout-bar__circle'">
                                            <template v-if="step.state === 'completed'">
                                                <span class="far fa-check"></span>
                                            </template>
                                            <template v-else>
                                                <span>{{ index }}</span>
                                            </template>
                                        </inertia-link>
                                    </div>
                                    <inertia-link :href="step.url" :class="'c-checkout-bar__name'">{{ step.title }}</inertia-link>
                                </template>
                                <template v-else>
                                    <div class="c-checkout-bar__line">
                                        <span class="c-checkout-bar__circle">
                                            <template v-if="step.state === 'completed'">
                                                <span class="far fa-check"></span>
                                            </template>
                                            <template v-else>
                                                <span>{{ index }}</span>
                                            </template>
                                        </span>
                                    </div>
                                    <span class="c-checkout-bar__name">{{ step.title }}</span>
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="u-margin-vertical-large">
                <div v-if="$page.props.is_session_expired == 1" class="o-row o-flex--center">
                    <p class="u-margin-top-xsmall u-color-alert u-font-weight-bold">{{ translate('js.frontend.global.checkout_expired') }}</p>
                </div>
                <slot></slot>
            </div>
        </div>
    </master-layout>
</template>

<script>
    import MasterLayout from '../Shared/MasterLayout';
    import { Link } from '@inertiajs/vue3';

    export default {
        props: {
            steps: Object
        },
        components: {
            'inertia-link' : Link,
            'master-layout' : MasterLayout
        }
    }
</script>
