<template>
    <div class="c-hero" v-if="image_object || isHasSectionItemImageObject">
        <agile v-if="is_slider && isHasSectionItemImageObject" :options="agile_options" :speed="800" :swipe-distance="swiping_distance">
            <responsive-image
                v-for="section_item in data.sectionItems"
                :is_background_image="true"
                :class_name="'c-hero__slide o-background u-full-width c-hero__slide--' + this.hero_size"
                :key="section_item.id"
                :small="section_item.image_object.hero_small"
                :medium="section_item.image_object.hero_medium"
                :large="section_item.image_object.hero_large"
                :alt_text="section_item.image_object.alt_text ? section_item.image_object.alt_text : section_item.title">
                    <div class="o-row u-full-height o-flex o-flex--middle" :class="section_item.translated_text_alignment == 'right' && 'o-flex--justify-end'">
                        <div class="o-col o-col--sm-12 o-col--md-6">
                            <div class="c-hero__content">
                                <text-content :item="section_item" :font_size="'large'" :font_color="'white'" :text_alignment="section_item.translated_text_alignment" :is_hero="true" :is_first_heading_tag="is_first_heading_tag"/>
                            </div>
                        </div>
                    </div>
            </responsive-image>
        </agile>
        <div v-if="!is_slider && image_object">
            <responsive-image
                :class_name="'c-hero__slide o-background u-full-width c-hero__slide--' + hero_size"
                :alt_text="image_object.alt_text ? image_object.alt_text : ''"
                :is_background_image="true"
                :small="image_object.hero_small"
                :medium="image_object.hero_medium"
                :large="image_object.hero_large"/>
        </div>
    </div>
</template>

<script>
    import { VueAgile } from 'vue-agile';
    import TextContent from './TextContent.vue';
    import ResponsiveImage from './ResponsiveImage';

    export default {
        props: {
            data : Object,
            hero_size : String,
            is_slider: Boolean,
            image_object: Object,
            is_first_heading_tag : Boolean
        },
        components: {
            'agile' : VueAgile,
            'text-content' : TextContent,
            'responsive-image' : ResponsiveImage
        },
        computed: {
            /**
             * Check if there are multiple section items and if one of the section items does not have an image
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return {Boolean}
             */
            isHasSectionItemImageObject() {
                if (this.data.sectionItems.length > 0 && this.data.sectionItems.some((section_item) => section_item.image_object != null)) {
                    return true;
                }
                return false;
            }
        },
        data() {
            return {
                agile_options: {
                    navButtons: false,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 6000,
                    infinite: true
                },
                swiping_distance: 0
            }
        },
        mounted() {
            this.getSwipingDistance();
            window.addEventListener('resize', this.getSwipingDistance, { passive: true });
        },
        methods: {
            /**
             * Get swiping distance
             *
             * @author Lennart van Welzen <lennart@click.nl>
             * @return void
             */
            getSwipingDistance() {
                let window_width = window.innerWidth;
                if (window_width < getBreakpoint('lg')) {
                    this.swiping_distance = 20;
                } else {
                    this.swiping_distance = 99999;
                }
            }
        }
    }
</script>
