<template>
    <inertia-head>
        <title>{{ translate(`js.frontend.error.${this.status}_title`) }} {{ translate('js.frontend.page_title.brand') }}</title>
    </inertia-head>
    <div class="o-flex--grow o-row o-flex--center u-margin-vertical-medium">
        <div class="o-col o-col--sm-12 o-col--md-10">
            <div class="o-flex o-flex--row o-flex--middle o-flex--center u-margin-bottom-medium">
                <inertia-link :href="`/${$page.props.locale}/`" :class="'c-nav__logo'">
                    <img :src="`${$page.props.cdn_url}/images/frontend/logo_client_${$page.props.locale}.svg`" :alt="translate('js.frontend.header.brand_name') + ' ' + translate('js.frontend.header.slogan')" class="u-full-width">
                </inertia-link>
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
    import { Link, Head } from '@inertiajs/vue3';

    export default {
        components: {
            'inertia-link' : Link,
            'inertia-head' : Head
        },
        props: {
            status: Number,
        }
    }
</script>