<script>
    export default {
        methods: {
            /**
             * Use js-localization laravel package to translate text
             *
             * @param path
             * @param prefix
             * @param items
             * @returns Lang translated text
             */
            translate: function(path, items) {
                return Lang.get(path, items);
            },

            /**
             * Use the s-localization laravel package to check if the translation exists
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param path
             * @param items
             * @return Boolean
             */
            hasTranslation: function(path, items) {
                return Lang.has(path, items);
            },
            
             /**
             * Use js-localization laravel package to translate text
             *
             * @param path
             * @param {Integer} count           Subject count for pluralization.
             * @param {Object} [replacements]   Associative array: { variableName: "replacement", ... }
             * @returns Lang translated text
             */
            choice: function(path, add_prefix, count, replacements) {
                add_prefix = add_prefix || false;
                return Lang.choice((add_prefix == false ? 'frontend.js.' : '') + path, count, replacements);
            },

            /**
             * Format the price to a dutch price with 2 digits
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  string price
             * @return float  
             */
            formatPrice: function(price)
            {
                // Parse price to float so all prices will be prefixed with a euro sign
                price = parseFloat(price);
                let formatted_price = price.toLocaleString('nl-NL', {
                    style: 'currency', 
                    currency: 'EUR', 
                    minimumFractionDigits: 2
                });

                return formatted_price;
            }

        }
    }
</script>
