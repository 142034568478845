<template>
    <section :class="is_first_section ? 'u-margin-top-xlarge u-margin-bottom-large' : 'u-margin-vertical-large'">
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <text-content :item="data" :button_type="'secondary'" :is_first_heading_tag="is_first_heading_tag"/>
            </div>
        </div>
    </section>
</template>
<script>
    import TextContent from '../Components/TextContent.vue';

    export default {
        props: {
            data : Object,
            is_first_section: Boolean,
            is_first_heading_tag : Boolean
        },
        components: {
            'text-content' : TextContent
        }
    }
</script>