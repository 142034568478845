<template>
    <div class="o-row c-alert--sticky">
        <div class="o-col o-col--sm-12">
            <div class="c-alert c-alert--warning o-flex o-flex--middle">
                <span class="far fa-triangle-exclamation u-margin-right-xsmall"></span> {{ translate('js.frontend.global.preview_mode_' + entity) }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entity']
    }
</script>