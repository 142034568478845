<template>
    <footer class="c-footer u-full-width">
        <div class="o-row">
            <div class="o-col o-col--sm-12">
                <div class="c-footer__inner">
                    <span>{{ translate('js.frontend.footer.copyright') }}</span>
                    <ul class="c-footer__items o-list-unstyled">
                        <template v-if="this.footer_links">
                            <li v-for="(footer_link, key) in this.footer_links" class="c-footer__item" :key="key">
                                <inertia-link v-if="footer_link.title && footer_link.slug" :href="`/${$page.props.locale}/${footer_link.slug}`">{{ footer_link.title }}</inertia-link>
                            </li>
                        </template>
                        <li class="c-footer__item">
                            <a :href="$page.props.terms_url" target="_blank" rel="noopener" class="c-footer__item-anchor u-cursor-pointer">
                                {{ translate('js.frontend.footer.terms') }}
                            </a>
                        </li>
                        <li class="c-footer__item">
                            <a :href="$page.props.privacy_policy_url" target="_blank" rel="noopener" class="c-footer__item-anchor u-cursor-pointer">
                                {{ translate('js.frontend.footer.privacy') }}
                            </a>
                        </li>

                        <!-- Disabled by using v-if so the element wont be shown as comment in the inspector (Only v-if is shown as comment now) -->
                        <li v-if="false" class="c-footer__item">
                            <button type="button" class="c-footer__item-anchor u-cursor-pointer" @click="this.toggleOverlay()">
                                {{ translate('js.frontend.footer.cookie_settings') }}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';

    export default {
        components: {
            'inertia-link' : Link
        },
        data: () => {
            return {
                footer_links: {},
            }
        },
        mounted() {
            this.footer_links = usePage().props.footer_links;
        },
        methods: {
            toggleOverlay() {
                window.dispatchEvent(CustomEvent('toggleOverlay', { detail: true }));
            }
        }
    }
</script>