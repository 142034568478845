<template>
    <div class="o-row">
        <template v-if="is_loading">
            <div v-for="card in this.loading_cards_amount" class="o-col o-col--sm-12 o-col--md-6 o-col--lg-4 u-margin-bottom-small" :key="card">
                <card :is_loading="true"/>
            </div>
        </template>
        <template v-else>
            <div v-for="card in this.cards" class="o-col o-col--sm-12 o-col--md-6 o-col--lg-4 u-margin-bottom-small" :key="card.id">
                <card :data="card"/>
            </div>
        </template>
    </div>
</template>

<script>
    import Card from './Card.vue';
    
    export default {
        props: {
            cards : Object,
            is_loading : Boolean
        },
        data() {
            return {
                loading_cards_amount: 3
            }
        },  
        components: {
            'card' : Card
        }
    }
</script>