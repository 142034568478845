<template>
    <div class="o-flex--grow u-margin-vertical-large">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <errors :errors="errors"></errors>
                <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">
                    <template v-if="status === 'success'">
                        {{ translate('js.frontend.account.reset_password_confirm') }}
                    </template>
                    <template v-else>
                        {{ translate('js.frontend.account.reset_password') }}
                    </template>
                </h1>
            </div>
        </div>
        <div v-if="!status" class="o-row o-flex--center" >
            <div class="o-col o-col--md-12 o-col--lg-10">
                <form v-on:submit.prevent="submitForm" class="c-form">
                    <div class="c-form--grow">
                        <div class="c-form__group">
                            <label for="new-password">{{ translate('js.frontend.account.new_password') }}</label>
                            <input class="c-form__input" id="new-password" type="password" :placeholder="translate('js.frontend.account.new_password')" v-model="form.new_password">
                            <p v-if="hasError('new_password')" class="c-form__message c-form__message--error">
                                {{ getError('new_password') }}
                            </p>
                        </div>
                        <div class="c-form__group">
                            <label for="new-password-confirmation">{{ translate('js.frontend.account.repeat_password') }}</label>
                            <input class="c-form__input" id="new-password-confirmation" type="password" :placeholder="translate('js.frontend.account.repeat_password')" v-model="form.new_password_confirmation">
                            <p v-if="hasError('new_password_confirmation')" class="c-form__message c-form__message--error">
                                {{ getError('new_password_confirmation') }}
                            </p>
                        </div>
                    </div>
                    <div class="o-flex o-flex--justify">
                        <div class="c-form__submit">
                            <button :disabled="disable_submit" type="submit" class="c-button c-button--solid">
                                {{ translate('js.frontend.account.edit_password') }}<span class="c-button__icon far fa-arrow-right"></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="status === 'success' || status === 'error'" class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <p class="u-no-margin-top">{{ message }}</p>
                <inertia-link v-if="status === 'success'" :href="`/${$page.props.locale}/account/login`" :class="'c-button c-button--solid'">
                    {{ translate('js.frontend.account.click_to_login') }} <span class="c-button__icon far fa-chevron-right"></span>
                </inertia-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';
    import Errors from '../../Components/UI/Errors';

    export default {
        props: ['email'],
        components: {
            'inertia-link' : Link,
            'errors' : Errors
        },
        data() {
            return {
                form: {
                    email: this.email,
                },
                errors: null,
                disable_submit: false,
                status: null,
                message: null,
            }
        },
        mounted() {
            if(!this.email){
                this.status = 'error';
                this.message = Lang.get('js.frontend.password_reset.not_valid');
            }
        },
        methods: {
            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm: function()
            {
                this.message = null;
                this.status = null;
                this.disable_submit = true;
                this.errors = null;
                axios.post(`/${usePage().props.locale}/api/password-reset`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitSuccess: function(response)
            {
                this.message = response.data.message;
                this.status = response.data.status;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(errors)
            {
                this.disable_submit = false;

                if( errors.response.status == 422){
                    this.errors = errors.response;
                } else if( errors.response.status == 403 ) {
                    window.location.href = errors.response.data.route;
                }else{
                    this.errors = [];
                    this.errors['general'] = Lang.get('js.frontend.global.something_went_wrong');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                if(this.errors && this.errors.data){
                    return this.errors.data.errors[field][0];
                }
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors.data && this.errors.data.errors && this.errors.data.errors[field];
            },
        }
    }
</script>