<template>
    <div class="c-card__content">
        <header class="c-card__header">
            <inertia-link v-if="item.title && item.slug" :href="item.slug">
                <h2 class="c-card__title c-heading--small u-no-margin-top u-margin-bottom-small u-break-word">{{ item.title }}</h2>
            </inertia-link>
        </header>
        <div class="c-card__body o-flex o-flex--column">
            <div v-if="item.short_description">{{ item.short_description }}</div>
        </div>
        <footer class="c-card__footer u-margin-bottom-small">
            <div v-if="item.price_description" class="u-font-weight-bold u-margin-bottom-small">
                <span class="u-font-large">{{ item.price_description }}</span>
            </div>
            <div class="o-flex o-flex--middle o-flex--justify o-flex--wrap">
                <inertia-link v-if="item.button_text && item.slug" :href="item.slug" class="c-button c-button--solid u-margin-right-xsmall">
                    {{ item.button_text }} <span class="c-button__icon far fa-arrow-right"></span>
                </inertia-link>
                <inertia-link v-else-if="item.slug && item.button_text == ''" :href="item.slug" class="c-button c-button--solid u-margin-right-xsmall">
                    {{ translate('js.frontend.card_button_text') }} <span class="c-button__icon far fa-arrow-right"></span>
                </inertia-link>
                <span v-if="item.city" class="u-color-dark-gray u-padding-vertical-xsmall">
                    <span class="far fa-location"></span> {{ item.city }}
                </span>
            </div>
        </footer>
    </div>
</template>

<script>
    import { Link } from '@inertiajs/vue3';

    export default {
        components: {
            'inertia-link' : Link
        },
        props: {
            item: Object
        }
    }
</script>