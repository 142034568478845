/**
 * JS Translations
 *
 * @author Joost Ligthart <joost@click.nl>
 */
window.Lang = new class JsTranslation {

    /**
     * Fetches the desired text from the translations file.
     *
     * @author Joost Ligthart <joost@click.nl>
     * @param  string string_location
     * @param  object attribute_values
     * @return string
     */
    get(string_location, attribute_values = {}) {
        // Check if the translation doesn't exist. If so return the string_location.
        if (window.translations == undefined || window.translations[string_location] == undefined) {
            return string_location;
        }

        // Get the translation from the json file
        let translation =  window.translations[string_location];
        // Loop through the given attribute values and add them to the recieved translation.
        for (var attribute in attribute_values) {
            translation = translation.replace(`:${attribute}`, attribute_values[attribute]);
        }
        return translation;
    }

    /**
     * Checks if the translation exists
     *
     * @author Joost Ligthart <joost@click.nl>
     * @param  string string_location
     * @return boolean
     */
    has(string_location) {
        // Check if the translation doesn't exist. If so return the string_location.
        if (window.translations == undefined || window.translations[string_location] == undefined) {
            return false;
        }
        return true;
    }

    /**
     * Fetches the singular or plural version of the requested translation based on the given count
     *
     * @author Joost Ligthart <joost@click.nl>
     * @param  string string_location
     * @param  int count
     * @param  object attribute_values
     * @return string
     */
    choice(string_location, count, attribute_values={}) {
        // Check if the translation doesn't exist. If so return the string_location.
        if (window.translations == undefined || window.translations[string_location] == undefined) {
            return string_location;
        }

        // Get the translation from the json file and split it
        let splitted_translation =  window.translations[string_location].split('|');
        // When the count is one, use the singular version of this translation, otherwise the plural version
        let translation = '';
        if (count == 1) {
            translation = splitted_translation[0];
        } else {
            translation = splitted_translation[1];
        }
        // Loop through the given attribute values and add them to the recieved translation.
        for (var attribute in attribute_values) {
            translation = translation.replace(`:${attribute}`, attribute_values[attribute]);
        }
        return translation;
    }
}
