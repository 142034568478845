<template>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--md-12 o-col--lg-10">
            <errors :errors="errors"></errors>
            <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.checkout.overview.header') }}</h1>

            <div class="c-summary">
                <header class="c-summary__header u-margin-bottom-small">
                    <h2 class="c-heading--small u-no-margin-bottom u-no-margin-top">{{ translate('js.frontend.checkout.shopping_cart.shopping_cart') }}</h2>
                    <inertia-link :href="`/${$page.props.locale}/checkout/shopping-cart`" :class="'u-color-primary'">{{ translate('js.frontend.checkout.overview.edit') }}</inertia-link>
                </header>
                <div v-if="products.length > 0">
                    <shopping-cart-item :is_loading="is_loading" :is_edit_disabled="true" :is_overview="true" v-for="product in products" :key="product.id" :product="product"/>
                    <div class="c-panel u-no-padding-top o-flex o-flex--justify u-font-medium">
                        <span class="u-font-weight-bold">{{ translate('js.frontend.checkout.shopping_cart.total') }}</span>
                        <span class="u-font-weight-bold">{{ formatPrice(totalPrice) }}</span>
                    </div>
                </div>
            </div>

            <div class="c-summary u-margin-top-medium">
                <header class="c-summary__header">
                    <h2 class="c-heading--small u-no-margin-bottom u-no-margin-top">{{ translate('js.frontend.checkout.overview.user_data') }}</h2>
                    <inertia-link :href="`/${$page.props.locale}/checkout/user-data`" :class="'u-color-primary'">{{ translate('js.frontend.checkout.overview.edit') }}</inertia-link>
                </header>
                <div class="c-summary__body">
                    <div class="c-summary__item c-summary__item--column">
                        <ul class="o-list-unstyled">
                            <li v-if="fullName != ''" class="o-flex o-flex--column u-margin-bottom-xsmall">
                                <span class="u-font-weight-bold">{{ translate('js.frontend.account.full_name') }}</span>
                                <span>{{ fullName }}</span>
                            </li>
                            <li v-if="$page.props.user.email != null" class="o-flex o-flex--column u-margin-bottom-xsmall">
                                <span class="u-font-weight-bold">{{ translate('js.frontend.account.email') }}</span>
                                <span v-if="$page.props.user.email != null">{{ $page.props.user.email }}</span>
                            </li>
                            <li v-if="$page.props.user.phone != null" class="o-flex o-flex--column u-margin-bottom-xsmall">
                                <span class="u-font-weight-bold">{{ translate('js.frontend.account.phone') }}</span>
                                <span v-if="$page.props.user.phone != null">{{ $page.props.user.phone }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="u-margin-top-small">
                <div class="c-checkbox-wrapper o-flex o-flex--right">
                    <input v-model="form.is_accepted_terms" class="c-checkbox" type="checkbox" id="is_accepted_terms">
                    <label v-html="translate('js.frontend.checkout.overview.is_accepted_terms', { privacy_policy_url: $page.props.privacy_policy_url, terms_url: $page.props.terms_url })" for="is_accepted_terms" class="c-checkbox-label"></label>
                </div>
                <p v-if="hasError('is_accepted_terms')" class="c-form__message c-form__message--error o-flex o-flex--right">
                    {{ getError('is_accepted_terms') }}
                </p>
            </div>

            <div class="o-flex o-flex--justify u-margin-top-small">
                <inertia-link :href="`/${$page.props.locale}/checkout/user-data`" :class="'c-button c-button--hollow c-button--secondary'">
                    <span class="c-button__icon--left far fa-arrow-left"></span> {{ translate('js.frontend.checkout.previous_step') }}
                </inertia-link>
                <button v-if="products.length > 0" @click="createOrder" :disabled="is_disabled" class="c-button c-button--solid">
                    <template v-if="totalPrice > 0">
                        {{ translate('js.frontend.checkout.order_pay') }}
                    </template>
                    <template v-else>
                        {{ translate('js.frontend.checkout.complete') }}
                    </template>
                    <span class="c-button__icon far fa-arrow-right"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import ShoppingCartItem from '../../Components/ShoppingCartItem';
    import { Link, usePage } from '@inertiajs/vue3';
    import Errors from '../../Components/UI/Errors';

    export default {
        props: {
            redirect_errors : Object
        },
        components: {
            'inertia-link'          : Link,
            'shopping-cart-item'    : ShoppingCartItem,
            'errors'                : Errors
        },
        data: function() {
            return {
                products: {},
                form: {
                    is_accepted_terms: false
                },
                errors: null,
                is_loading: true,
                is_disabled: false
            }
        },
        computed: {
            /**
             * Returns the full name of the user
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return  string
             */
            fullName() {
                let full_name = '';
                if(usePage().props.user.first_name != undefined && usePage().props.user.first_name != null){
                    full_name = usePage().props.user.first_name;
                }
                if(usePage().props.user.last_name != undefined && usePage().props.user.last_name != null){
                    if(usePage().props.user.insertion != undefined && usePage().props.user.insertion != null){
                        full_name = full_name + ' ' + usePage().props.user.insertion;
                    }
                    full_name = full_name + ' ' + usePage().props.user.last_name;
                }
                return full_name;
            },
            /**
             * Calculates the total price of the ordered tickets
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return int
             */
            totalPrice() {
                let total = 0;
                this.products.forEach((product) => {
                    product.product_variants.forEach(product_article => {
                        total += (parseFloat(product_article.price) * parseInt(product_article.amount));
                    });
                });
                return total;
            }
        },
        mounted(){
            this.getShoppingcartContent();
        },
        methods: {
            /**
             * Get the content to populate the shopping cart
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return void
             */
            getShoppingcartContent:  function() {
                this.is_loading = true;
                axios.get(`/${usePage().props.locale}/api/checkout/get-shopping-cart-content`)
                    .then((response) => {
                        this.products = response.data;
                        this.errors = this.redirect_errors;
                        this.is_loading = false;
                    });
            },
            /**
             * Get the content to populate the shopping cart
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return void
             */
            createOrder:  function() {
                this.errors = null;
                this.is_disabled = true;

                axios.post(`/${usePage().props.locale}/api/checkout/create-order`, this.form)
                    .then((response) => {
                        window.location.href = response.data.route;
                    })
                    .catch((errors) => {
                        this.is_disabled = false;
                        if(errors.response){
                            // if the error status is 498 (token expired)
                            if(errors.response.status === 498 || errors.response.status === 403 ) {
                                window.location.href = errors.response.data.route;
                            } else {
                                this.errors = errors.response;
                            }
                        }
                    });
            },
            /**
             * Check if any field has any erro
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return  boolean
             */
            hasError: function(field)
            {
                return this.errors && this.errors.data && this.errors.data.errors && this.errors.data.errors[field];
            },
            /**
             * Get the error based on field
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  field
             * @return string
             */
            getError: function(field)
            {
                // if any error exists return string otherwise null
                if(this.hasError(field)) {
                    return this.errors.data.errors[field][0];
                }
                return null;
            }

        }
    }
</script>