<template>
    <template v-if="is_preview">
        <preview-notification entity="product"></preview-notification>
    </template>
    <article class="o-flex--grow u-bg-light-gray">
        <hero :image_object="product.image_object" :hero_size="'medium'" :is_slider="false"></hero>
        <section v-if="product" class="u-margin-vertical-large">
            <div class="o-row u-margin-vertical-large o-flex o-flex--center">
                <div class="o-col o-col--md-12 o-col--lg-10">
                    <h1 v-if="product.title" class=" c-heading--large u-no-margin-top u-margin-bottom-small">{{ product.title }}</h1>
                    <div v-if="product.price_description || product.old_price_description" class="u-margin-bottom-small">
                        <span v-if="product.price_description" class="u-font-weight-bold u-font-large">{{ product.price_description }}</span>
                        <div v-if="product.old_price_description" class="u-color-dark-gray">
                            <span>{{ product.old_price_description }}</span>
                        </div>
                    </div>
                    <button v-on:click.prevent="goToOrderForm" class="c-button c-button--solid">
                        {{ product.button_text ? product.button_text : translate('js.frontend.card_button_text') }} <span class="c-button__icon far fa-arrow-right"></span>
                    </button>
                </div>
                <div v-if="product.description" v-html="product.description" class="o-col o-col--md-12 o-col--lg-10 u-margin-top-medium"></div>
                <div v-if="product.image_slider && product.image_slider.length > 0" class="o-col o-col--md-12 o-col--lg-10 u-margin-top-medium">
                    <image-wrapper :images="product.image_slider" :title="product.title ? product.title : null"></image-wrapper>
                </div>
                <div v-if="product.location" v-html="product.location" class="o-col o-col--md-12 o-col--lg-10 u-margin-top-medium"></div>
                <div v-if="product.conditions" v-html="product.conditions" class="o-col o-col--md-12 o-col--lg-10 u-margin-top-medium"></div>
            </div>
        </section>
        <section-ticket-select :product="product" ref="ticket_form"></section-ticket-select>
    </article>
</template>

<script>
    import Hero                 from '../Components/Hero';
    import Image                from '../Components/Image';
    import SectionTicketSelect  from '../Sections/section-ticket-select';
    import PreviewNotification  from '../Components/UI/PreviewNotification.vue';

    export default {
        props: {
            product: Object,
            is_preview: Boolean
        },
        components: {
            'hero'                  : Hero,
            'image-wrapper'         : Image,
            'section-ticket-select' : SectionTicketSelect,
            'preview-notification'  : PreviewNotification
        },
        data: () => {
            return {
                product_variants: []
            }
        },
        methods: {
            goToOrderForm: function() {
                this.$refs.ticket_form.$el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
</script>
