<template>
    <section :class="is_first_section ? 'u-margin-top-xlarge u-margin-bottom-large' : 'u-margin-vertical-large'">
        <div class="o-row">
            <div class="o-col o-col--sm-12">
                <text-content :item="data" :font_size="'medium'" :is_first_heading_tag="is_first_heading_tag"/>
            </div>
        </div>
        <card-overview :cards="cards" :is_loading="is_loading"></card-overview>
    </section>
</template>

<script>
    import TextContent from '../Components/TextContent';
    import CardOverview from '../Components/CardOverview.vue';
    import { usePage } from '@inertiajs/vue3';

    export default {
        props: {
            data : Object,
            is_first_section: Boolean,
            is_first_heading_tag: Boolean
        },
        components: {
            'card-overview' : CardOverview,
            'text-content' : TextContent
        },
        data: () => {
            return {
                cards: [],
                is_loading: false
            }
        },
        mounted: function() {
            this.getProducts();
        },
        methods: {
            getProducts: function() {
                this.is_loading = true;
                axios.get(`/${usePage().props.locale}/api/card-overview/${this.data.id}/get-products`)
                    .then((response) => {
                        this.cards = response.data
                        this.is_loading = false;
                    });
            }
        }
    }
</script>