<template>
    <div class="c-panel__items">
        <div v-if="is_max_amount" class="u-margin-top-xsmall u-margin-bottom-xsmall u-color-alert u-font-weight-bold">
            {{ translate('js.frontend.checkout.ticket_limit_exceeded', {ticket_limit:max_ticket_amount}) }}
        </div>
        <template v-for="product_variant in product_variants" :key="product_variant.id">
            <div class="c-panel__select-wrapper o-flex--column" :class="{ 'c-panel__select-wrapper--disabled': !product_variant.available }">
                <div class="o-flex o-flex--justify">
                    <div class="o-flex o-flex--middle">
                        <span>{{ product_variant.trans_title }}</span>
                    </div>
                    <div class="o-flex o-flex--middle">
                        <v-select v-if="product_variant.available" v-model="modelValue[product_variant.id]"
                            :options="getOrderableAmounts"
                            :clearable="false"
                            :disabled="!product_variant.available"
                            :searchable="false"
                            @option:selected="maxAmountCheck()"
                        />
                        <span v-if="product_variant.available" class="u-margin-left-xsmall u-margin-right-xsmall">x</span>
                        <span v-if="!product_variant.available" class="u-margin-left-xsmall u-margin-right-xsmall">{{ translate('js.frontend.product.unavailable') }}</span>
                        <span class="c-panel__price">
                            <span v-if="product_variant.price !== null" class="u-margin-left-xsmall">{{ formatPrice(product_variant.price) }}</span>
                        </span>
                    </div>
                </div>
                <p v-if="product_variant.trans_short_description" class="u-color-dark-gray u-no-margin-bottom">{{ product_variant.trans_short_description }}</p>
            </div>
        </template>
        <div class="c-panel u-no-padding-top o-flex o-flex--justify u-font-medium">
            <span class="u-font-weight-bold">{{ translate('js.frontend.checkout.shopping_cart.total') }}</span>
            <span class="u-font-weight-bold">{{ totalPrice }}</span>
        </div>
    </div>
</template>

<script>
    import vSelect          from 'vue-select';
    import { usePage }      from '@inertiajs/vue3';

    export default {
        props: {
            modelValue: Object,
            product_variants : Object
        },
        emits: ['update:modelValue', 'selected-tickets', 'shopping-cart-validation'],
        components: {
            'v-select' : vSelect
        },
        data() {
            return {
                is_max_amount: false,
                max_ticket_amount: usePage().props.max_ticket_amount,
            }
        },
        computed : {
            /**
             * Calculates the total price of the ordered tickets
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return int
             */
            totalPrice() {
                let total = 0;

                Object.keys(this.modelValue).forEach(key => {
                    let product_variant = this.product_variants.find(product_variant =>
                            (
                               product_variant.id == key
                            )
                        );

                    if (product_variant !== undefined) {
                        total += ( parseFloat(product_variant.price) * parseInt(this.modelValue[key]) );
                    }
                });

                return this.formatPrice(total);
            },
            /**
             * Returns an array counting up from 0 to 10
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return array
             */
            getOrderableAmounts: function() {
                let count = [];
                for(let i= 0; i < 11; i++) {
                    count.push(i);
                }
                return count;
            }
        },
        methods: {
            /**
             * Check if max amount is selected
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return  {void}
             */
            maxAmountCheck: function() {
                let total = 0;

                Object.keys(this.modelValue).forEach(key => {
                    total += this.modelValue[key];
                });

                this.is_max_amount = (total > this.max_ticket_amount) ?? false;
            },
            /**
             * Format the price to a dutch price with 2 digits
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  string price
             * @return float
             */
            formatPrice: function(price) {
                // Parse price to float so all prices will be prefixed with a euro sign
                price = parseFloat(price);
                let formatted_price = price.toLocaleString('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2
                });

                return formatted_price;
            }
        }
    }
</script>
