<template>
    <div class="o-flex--grow u-margin-vertical-large">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <errors :errors="errors"></errors>
                <h2 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.change_password') }}</h2>
            </div>
        </div>
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <form class="c-form" v-on:submit.prevent="submitForm">
                    <div class="c-form--grow">
                        <div class="c-form__group">
                            <label for="current-password">{{ translate('js.frontend.account.current_password') }}</label>
                            <input class="c-form__input" id="current-password" type="password" v-model="form.current_password">
                            <p v-if="hasError('current_password')" class="c-form__message c-form__message--error">
                                {{ getError('current_password') }}
                            </p>
                        </div>
                        <div class="c-form__group">
                            <label for="new-password">{{ translate('js.frontend.account.new_password') }}</label>
                            <input class="c-form__input" id="new-password" type="password" v-model="form.new_password">
                            <p v-if="hasError('new_password')" class="c-form__message c-form__message--error">
                                {{ getError('new_password') }}
                            </p>
                        </div>
                        <div class="c-form__group">
                            <label for="new-password-confirmation">{{ translate('js.frontend.account.repeat_password') }}</label>
                            <input class="c-form__input" id="new-password-confirmation" type="password" v-model="form.new_password_confirmation">
                            <p v-if="hasError('new_password_confirmation')" class="c-form__message c-form__message--error">
                                {{ getError('new_password_confirmation') }}
                            </p>
                        </div>
                    </div>
                    <div class="o-flex o-flex--justify">
                        <inertia-link :href="`/${$page.props.locale}/account/dashboard`" class="c-button c-button--hollow c-button--secondary">
                            <span class="c-button__icon--left far fa-arrow-left"></span> {{ translate('js.frontend.global.go_back') }}
                        </inertia-link>
                        <div class="c-form__submit">
                            <button :disabled="disable_submit" type="submit" class="c-button c-button--solid">
                                {{ translate('js.frontend.global.change') }} <span class="c-button__icon far fa-arrow-right"></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';
    import Errors      from '../../Components/UI/Errors';

    export default {
        components: {
            'inertia-link' : Link,
            'errors' : Errors
        },
        data() {
            return {
                form: {},
                errors: null,
                disable_submit: false,
            }
        },
        methods: {
            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm: function()
            {
                this.disable_submit = true;
                this.errors = null;
                axios.post(`/${usePage().props.locale}/api/my-account/user/change-password`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitSuccess: function(response)
            {
                window.location.href = response.data.route;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(errors)
            {
                this.disable_submit = false;

                if( errors.response.status == 422){
                    this.errors = errors.response;
                } else if( errors.response.status == 403 ) {
                    window.location.href = errors.response.data.route;
                }else{
                    this.errors = [];
                    this.errors['general'] = Lang.get('js.frontend.global.something_went_wrong');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                if(this.errors && this.errors.data){
                    return this.errors.data.errors[field][0];
                }
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors.data && this.errors.data.errors && this.errors.data.errors[field];
            },
        }
    }
</script>
