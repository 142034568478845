<template>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--md-12 o-col--lg-10">
            <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.login') }}</h1>
        </div>
    </div>
    <div class="o-row o-flex--center">
        <div v-if="error_string" class="o-col o-col--md-12 o-col--lg-10 u-color-alert">
            <p>{{ error_string }}</p>
        </div>
        <login-form @toggle-password-forget="handlePasswordForget"></login-form>
        <register-email-form :is_without_account_enabled="true" :is_checkout="true" :email="email" :class="is_password_reset_toggled && 'u-hidden'"></register-email-form>
    </div>
</template>

<script>
    import RegisterEmailForm    from '../../Components/Auth/RegisterEmailForm';
    import LoginForm            from '../../Components/Auth/LoginForm';

    export default {
        data() {
            return{
                is_password_reset_toggled: false
            }
        },
        props : {
            error_string: String,
            email: {
                type: String,
                default: null
            }
        },
        components: {
            'register-email-form'   : RegisterEmailForm,
            'login-form'            : LoginForm
        },
        methods: {
            /**
             * Hides the "I'm a new customer" window when the "forgot password" button is pressed.
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return  void
             */
             handlePasswordForget: function(value) {
                this.is_password_reset_toggled = value;
            },
        }
    }
</script>