<template>
    <div class="o-col o-col--sm-12 o-col--lg-5">
        <panel :title="translate('js.frontend.account.sign_up_new_user')" :is_form="true">
            <form class="c-form">
                <div class="c-form__group">
                    <label for="email-register">{{ translate('js.frontend.account.email') }}</label>
                    <input class="c-form__input" id="email-register" type="email" :placeholder="translate('js.db.placeholder.email')" v-model="form.email" :maxLength="256">
                    <p v-if="hasError('email')" class="c-form__message c-form__message--error">
                        {{ getError('email') }}
                    </p>
                </div>
                <div class="c-form__button-group">
                    <div class="c-form__submit">
                        <button class="c-button c-button--solid" v-on:click.prevent="submitForm(false)" :disabled="disable_submit">
                            {{ translate('js.frontend.account.sign_up') }}
                            <span class="c-button__icon far fa-arrow-right"></span>
                        </button>
                    </div>
                    <button type="button" class="c-button--clear" v-if="is_without_account_enabled" v-on:click="submitForm(true)">
                        {{ translate('js.frontend.account.without_account_link') }}
                        <span class="c-button__icon far fa-arrow-right"></span>
                    </button>
               </div>
            </form>
        </panel>
    </div>
</template>

<script>
    import Panel        from '../../Components/Panel';
    import { usePage }  from '@inertiajs/vue3';

    export default {
        props : {
            is_without_account_enabled: {
                type: Boolean,
                default: false
            },
            is_checkout: {
                type: Boolean,
                default: false
            },
            email: {
                type: String,
                default: null,
            }
        },
        data() {
            return {
                form: {
                    is_without_account: false,
                },
                errors: null,
                disable_submit: false
            }
        },
        components: {
            'panel'        : Panel
        },
        mounted() {
            if(this.email){
                this.form.email = this.email;
            }
        },
        methods: {
            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm: function(is_without_account)
            {
                this.disable_submit = true;
                this.errors = null;
                this.form.is_without_account = is_without_account;
                this.form.is_checkout = this.is_checkout;
                axios.post(`/${usePage().props.locale}/api/register-email`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            submitSuccess: function(response)
            {
                this.disable_submit = false;
                window.location.href = response.data.route;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(error)
            {
                this.disable_submit = false;
                if(error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.error_messages = Lang.get('frontend.js.global.422_error');
                } else {
                    this.error_messages = Lang.get('frontend.js.global.error');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                return this.errors[field][0];
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors[field] && (this.errors[field].length > 0);
            },
        }
    }
</script>
