<template>
    <div class="o-flex--grow u-margin-vertical-large">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.login') }}</h1>
            </div>
        </div>
        <div class="o-row o-flex--center">
            <login-form @toggle-password-forget="handlePasswordForget"></login-form>
            <register-email-form :class="is_password_reset_toggled && 'u-hidden'"></register-email-form>
        </div>
    </div>
</template>

<script>
    import RegisterEmailForm    from '../../Components/Auth/RegisterEmailForm';
    import LoginForm            from '../../Components/Auth/LoginForm';
    import { usePage }          from '@inertiajs/vue3';

    export default {
        data() {
            return {
                form: {},
                errors: null,
                is_password_reset_toggled: false
            }
        },
        components: {
            'register-email-form'   : RegisterEmailForm,
            'login-form'            : LoginForm
        },
        methods: {

            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm: function()
            {
                this.disable_submit = true;
                this.errors = null;
                axios.post(`/${usePage().props.locale}/api/auth/register-email`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            /**
             * Redirects the user to the next page after a succesful submit
             *
             * @author @author Bas Lokerman <bas@click.nl>
             * @return  void
             */
            submitSuccess: function(response)
            {
                this.disable_submit = false;
                window.location.href = response.data.route;
            },

            /**
             * Hides the "I'm a new customer" window when the "forgot password" button is pressed.
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return  void
             */
            handlePasswordForget: function(value) {
                this.is_password_reset_toggled = value;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(error)
            {
                this.disable_submit = false;
                if(error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.error_messages = Lang.get('frontend.js.global.422_error');
                } else {
                    this.error_messages = Lang.get('frontend.js.global.error');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                return this.errors[field][0];
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors[field] && (this.errors[field].length > 0);
            },
        }
    }
</script>
