<template>
    <article v-if="is_loading" class="c-card u-margin-bottom-small u-overflow-hidden">
        <div class="c-card__content">
            <div class="c-block-loader c-block-loader--large"></div>
            <div class="c-block-loader"></div>
            <div class="c-block-loader"></div>
            <div class="c-block-loader c-block-loader--image u-no-margin-bottom"></div>
        </div>
    </article>
    <article v-else class="c-card c-card--loaded u-margin-bottom-small">
        <inertia-link v-if="data.slug" :href="data.slug">
            <figure class="c-card__image-wrapper u-overflow-hidden">
                <responsive-image
                    :class_name="'c-card__image'"
                    :alt_text="data.image_object.alt_text ? data.image_object.alt_text : data.title"
                    :is_background_image="false"
                    :small="data.image_object.card_small"
                    :medium="data.image_object.card_medium"
                    :large="data.image_object.card_large">
                </responsive-image>
            </figure>
        </inertia-link>
        <card-content :item="data"></card-content>
    </article>
</template>

<script>
    import CardContent from './CardContent';
    import ResponsiveImage from './ResponsiveImage';
    import { Link } from '@inertiajs/vue3';

    export default {
        props: {
            data : Object,
            is_loading : Boolean
        },
        components: {
            'card-content' : CardContent,
            'responsive-image' : ResponsiveImage,
            'inertia-link' : Link
        }
    }
</script>
