<template>
    <form class="c-form" v-on:submit.prevent="submitForm">
        <success v-if="success_message" v-model="success_message" :temporary_use="true"></success>
        <p v-if="!disable_submit">{{ translate('js.frontend.password_reset.explanation') }}</p>
        <div v-if="!disable_submit" class="c-form--grow u-margin-top-xsmall">
            <div class="c-form__group">
                <label for="email">{{ translate('js.frontend.account.email') }}</label>
                <input class="c-form__input" id="email" type="email" v-model="form.email" :placeholder="translate('js.db.placeholder.email')" :maxLength="256">
                <p v-if="hasError('email')" class="c-form__message c-form__message--error">
                    {{ getError('email') }}
                </p>
            </div>
        </div>
        <div class="c-form__button-group">
            <div v-if="!disable_submit" class="c-form__submit">
                <button class="c-button c-button--solid" type="submit" :disabled="disable_submit">
                    {{ translate('js.frontend.global.send') }}
                    <span class="c-button__icon far fa-arrow-right"></span>
                </button>
            </div>

            <button v-if="disable_submit" v-on:click.prevent="closeForm()" class="c-button c-button--hollow c-button--secondary" type="button">
                {{ translate('js.frontend.account.login') }}
                <span class="c-button__icon far fa-arrow-right"></span>
            </button>

            <button v-if="!disable_submit" v-on:click.prevent="closeForm()" type="button" class="c-button--clear">
                {{ translate('js.frontend.account.login') }}
                <span class="c-button__icon far fa-arrow-right"></span>
            </button>
        </div>
    </form>
</template>

<script>
    import Success      from '../UI/Success';
    import { usePage }  from '@inertiajs/vue3';

    export default {
        components: {
            'success'       : Success,
        },
        data() {
            return {
                form: {},
                errors: null,
                success_message: null,
            }
        },
        emits: ['close'],
        methods: {
             /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm: function()
            {
                this.disable_submit = true;
                this.errors = null;
                this.success_message = null;
                axios.post(`/${usePage().props.locale}/api/password-forget`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitSuccess: function(response)
            {
                this.success_message = response.data.success;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(error)
            {
                this.disable_submit = false;
                if(error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.error_messages = Lang.get('frontend.js.global.422_error');
                } else {
                    this.error_messages = Lang.get('frontend.js.global.error');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                return this.errors[field][0];
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors[field] && (this.errors[field].length > 0);
            },

            /**
             * Close form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            closeForm: function() {
                this.$emit('close');
            },
        }
    }
</script>
