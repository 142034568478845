<template>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--md-12 o-col--lg-10">
            <div v-if="is_error" class="c-alert c-alert--alert c-alert--radius">
                {{ translate('js.frontend.checkout.errors.payment_error') }}
            </div>
            <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top" v-if="!is_loading">{{ translate('js.frontend.checkout.payment.header') }}</h1>
            <div v-if="is_loading" class="c-panel__select-wrapper c-panel__select-wrapper--loading">
                <header class="c-block-loader c-block-loader--large c-block-loader--title"></header>
                <div class="c-block-loader c-block-loader"></div>
                <div class="c-block-loader c-block-loader u-no-margin-bottom"></div>
            </div>

            <div ref="payment_provider"></div>

            <button v-if="!is_loading" @click="cancelPayment('manual_cancel')" class="c-button c-button--hollow c-button--secondary u-margin-top-small">{{ translate('js.frontend.checkout.cancel_payment') }}</button>
        </div>
    </div>
</template>

<script>
    import AdyenCheckout from '@adyen/adyen-web';
    import '@adyen/adyen-web/dist/adyen.css';
    import { usePage }  from '@inertiajs/vue3';

    export default {
        props: {
            payment_data: Object
        },
        data: function() {
            return {
                configuration: {},
                redirect_result: null,
                payment_method: null,
                order_id: null,
                is_error: false,
                is_loading: true,
            }
        },
        mounted: function() {
            // Configure Adyen
            this.configAdyenCheckout();
            // Check if we got session data. If so, we have to shown the payment form.
            if(this.payment_data.session_data != undefined) {
                this.is_loading = false;
                // When the payment data is undefined and the session is not valid. Return to the overview page with error.
                if((this.configuration == null || this.configuration == undefined ) || (this.configuration && this.configuration.session.id == null)) {
                    window.location = `/${usePage().props.locale}/checkout/overview?payment_error=1`;
                }
                this.$nextTick(function(){
                    this.initAdyenCheckout();
                });
            } else {
                //Disable payment title and start loading animation
                this.is_loading = true;

                // No session data found, we got redirected back from the payment issuer.
                this.$nextTick(function(){
                    this.handleRedirectResult();
                });
            }
        },
        methods: {
            configAdyenCheckout: function() {
                this.configuration = {
                    environment: this.payment_data.environment, // Change to 'live' for the live environment.
                    clientKey: this.payment_data.client_key,
                    session: {
                        id: this.payment_data.id, // Unique identifier for the payment session.
                        sessionData: this.payment_data.session_data // The payment session data.
                    },
                    onPaymentCompleted: ( result, component ) => {
                        let data = {
                            redirect_result : this.payment_data.redirect_result,
                            order_id: this.payment_data.order_id,
                            payment_method: localStorage.getItem('payment_method')
                        };
                        localStorage.removeItem('payment_method');
                        axios.post(`/${usePage().props.locale}/api/checkout/payment/adyen-complete`, data)
                            .then((response) => {
                                window.location.href = response.data.route
                            })
                            .catch((errors) => {
                                if(errors.response){
                                    // Check if response is of a predefined format
                                    if (errors.response.data && errors.response.data.route) {
                                        window.location.href = errors.response.data.route;
                                    } else {
                                        // The backend gave a response we did not expect.
                                        window.location = `/${usePage().props.locale}/checkout/overview?payment_error=1`;
                                    }
                                }
                            })
                    },
                    beforeSubmit: (data, component, actions) => {
                        switch(data.paymentMethod.type) {
                            case 'ideal':
                                localStorage.payment_method = 'ideal';
                                actions.resolve(data)
                                break;
                            case 'scheme':
                                localStorage.payment_method = 'credit_card';
                                actions.resolve(data)
                                break;
                            case 'applepay':
                                localStorage.payment_method = 'apple_pay';
                                actions.resolve(data)
                                break;
                            case 'paywithgoogle':
                                localStorage.payment_method = 'google_pay';
                                actions.resolve(data)
                                break;
                            default:
                                actions.reject(data);
                                window.location = `/${usePage().props.locale}/checkout/overview?payment_error=1`;
                                break;
                        }
                    },
                    onError: (error, component) => {
                        this.cancelPayment('expired');
                        this.is_error = true;
                    },
                };
            },
            async initAdyenCheckout() {
                // Create an instance of AdyenCheckout using the configuration object.
                const checkout = await AdyenCheckout(this.configuration);

                // Create an instance of Drop-in and mount it to the container you created.
                const dropinComponent = checkout.create('dropin').mount(this.$refs.payment_provider);
            },
            async handleRedirectResult() {
                const searchParams = new URLSearchParams(location.href);
                this.redirect_result = searchParams.get('redirectResult');
                // Create an instance of AdyenCheckout to handle the shopper returning to the website.
                // Configure the instance with the sessionId you extracted from the returnUrl.
                const checkout = await AdyenCheckout(this.configuration);

                // Submit the redirectResult value you extracted from the returnUrl.
                checkout.submitDetails({ details: { redirectResult: searchParams.get('redirectResult') } });
            },
            cancelPayment: function(reason) {
                axios.post(`/${usePage().props.locale}/api/checkout/payment/cancel`, {cancel_reason: reason})
                    .then((response) => {
                        window.location.href = response.data.route
                    });
            }
        }
    }
</script>
