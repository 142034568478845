<template>
    <div class="c-nav__drawer" :class="(this.drawer_state === 'interacted' && (this.is_open ? 'c-nav__drawer--open' : 'c-nav__drawer--close'))">
        <navigation-items :nav_style="'primary'" :is_drawer="true"/>
    </div>
</template>

<script>
    import NavigationItems from './NavigationItems.vue';

    export default {
        emits: {
            handleDrawer: null
        },
        components: {
            'navigation-items' : NavigationItems
        },  
        props: {
            is_open: Boolean,
            drawer_state: String
        }
    }
</script>
