<template>
    <div class="o-flex--grow u-margin-vertical-large">

        <template v-if="verified_account && !is_already_verified">
            <div class="o-row o-flex--center u-padding-top-header">
                <div class="o-col o-col--md-12 o-col--lg-10">
                    <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.created_account') }}</h1>
                </div>
            </div>
            <div class="o-row o-flex--center">
                <div class="o-col o-col--md-12 o-col--lg-10 o-flex o-flex--column o-flex--top">
                    <p class="u-no-margin-top u-margin-bottom-small">{{ successDescription }}</p>
                    <inertia-link :href="`/${$page.props.locale}/account/login`" :class="'c-button c-button--solid'">
                        {{ successButton }} <span class="c-button__icon far fa-arrow-right"></span>
                    </inertia-link>
                </div>
            </div>
        </template>

        <template v-else-if="is_already_verified">
            <div class="o-row o-flex--center u-padding-top-header">
                <div class="o-col o-col--md-12 o-col--lg-10">
                    <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.account_already_activated') }}</h1>
                </div>
            </div>
            <div class="o-row o-flex--center">
                <div class="o-col o-col--md-12 o-col--lg-10">
                    <p class="u-no-margin-top u-margin-bottom-small">{{ translate('js.frontend.account.account_already_activated_success') }}</p>
                    <inertia-link :href="`/${$page.props.locale}/account/login`" :class="'c-button c-button--solid'">
                        {{ successButton }} <span class="c-button__icon far fa-arrow-right"></span>
                    </inertia-link>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="o-row o-flex--center u-padding-top-header">
                <div class="o-col o-col--md-12 o-col--lg-10">
                    <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.activate_account') }}</h1>
                </div>
            </div>
            <div class="o-row o-flex--center">
                <div class="o-col o-col--md-12 o-col--lg-10">
                    <template v-if="is_valid">
                        {{ translate('js.frontend.account.activate_email_failed') }}
                    </template>
                    <template v-else>
                        {{ translate('js.frontend.account.verify_url_not_valid') }}
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';

    export default {
        components: {
            'inertia-link' : Link
        },
        props: {
            verified_account: Boolean,
            is_valid: Boolean,
            is_already_verified: Boolean,
        },
        computed: {
            /**
             * Returns the success description based on the user login
             *
             * @author Bas Lokerman <bas@click.nl
             * @return string
             */
            successDescription: function() {
                if( usePage().props.is_logged_in ){
                    return Lang.get('js.frontend.account.success_logged_in');
                }else{
                    return Lang.get('js.frontend.account.created_account_success');
                }
            },
            /**
             * Returns the success button based on the user login
             *
             * @author Bas Lokerman <bas@click.nl
             * @return string
             */
            successButton: function() {
                if( usePage().props.is_logged_in ){
                    return Lang.get('js.frontend.account.button_to_account');
                }else{
                    return Lang.get('js.frontend.account.click_to_login');
                }
            },
        }
    }
</script>