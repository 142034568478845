<template>
    <section :class="is_first_section ? 'u-margin-top-xlarge u-margin-bottom-large' : 'u-margin-vertical-large'">
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <text-content :item="data" :is_first_section="is_first_section" :is_first_heading_tag="is_first_heading_tag"></text-content>
                <template v-for="(faq_category, key) in faq_category_data" :key="key">
                    <template v-if="faq_category.faq && faq_category.faq.length > 0">
                        <div class="u-margin-bottom-medium u-margin-top-medium">
                            <h1 v-if="faq_category.title && is_first_heading_tag" class="u-no-margin-top u-margin-bottom-small u-break-word">{{ faq_category.title }}</h1>
                            <h2 v-else class="u-no-margin-top u-margin-bottom-small u-break-word">{{ faq_category.title }}</h2>
                            <accordion :items="faq_category.faq"></accordion>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
    import TextContent  from '../Components/TextContent';
    import Accordion    from '../Components/Accordion';
    import { usePage }  from '@inertiajs/vue3';

    export default {
        props: {
            data : Object,
            is_first_section: Boolean,
            is_first_heading_tag: Boolean
        },
        components: {
            'text-content'  : TextContent,
            'accordion'     : Accordion
        },
        data: () => {
            return {
                faq_category_data: []
            }
        },
        mounted: function() {
            this.getFaqData();
        },
        methods: {
            getFaqData: function() {
                axios.get(`/${usePage().props.locale}/api/section/${this.data.id}/get-faq-categories`)
                    .then((response) => this.faq_category_data = response.data);
            }
        }
    }
</script>