<template>
    <header :class="(this.header_state === 'interacted' && (has_scrolled_down ? 'c-header--close' : 'c-header--open'))" class="c-header">
        <nav class="c-nav">
            <div class="o-row">
                <div class="o-col o-col--sm-12">
                    <div class="c-nav__bar o-flex o-flex--middle">
                        <button @click="openNavigationDrawer" class="c-hamburger u-hide-for-lg">
                            <span :class="{ 'c-hamburger__inner--animate': is_drawer_open }" class="c-hamburger__inner"></span>
                        </button>
                        <inertia-link @click="closeNavigationDrawer" :href="`/${$page.props.locale}`" :class="'c-nav__logo'">
                            <img class="u-full-width" :src="`${$page.props.cdn_url}/images/frontend/logo_client_${$page.props.locale}.svg`" :alt="translate('js.frontend.header.brand_name') + ' ' + translate('js.frontend.header.slogan')" width="98" height="40">
                        </inertia-link>
                        <navigation-items :nav_style="'primary'"></navigation-items>
                        <navigation-items :nav_style="'secondary'"></navigation-items>
                    </div>
                </div>
            </div>
            <navigation-drawer :is_open="is_drawer_open" :drawer_state="drawer_state"></navigation-drawer>
        </nav>
    </header>
</template>

<script>
    import NavigationItems from './NavigationItems';
    import NavigationDrawer from './NavigationDrawer';
    import { Link } from '@inertiajs/vue3';

    export default {
        components: {
            'navigation-items' : NavigationItems,
            'navigation-drawer' : NavigationDrawer,
            'inertia-link' : Link
        },
        data: () => {
            return {
                old_scroll_value: 0,
                new_scroll_value: 0,
                has_scrolled_down: false,
                is_drawer_open: false,
                drawer_state: '',
                header_state: ''
            }
        },
        mounted() {
            window.addEventListener('scroll', this.checkScrollDirection, { passive: true });
            window.addEventListener('resize', this.checkOnResize, { passive: true });
            window.addEventListener('closeNavigationDrawer', this.closeNavigationDrawer, { passive: true });
        },
        methods: {
            /**
             * Checks if mouse wheel scrolls up or down
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
            checkScrollDirection() {
                this.new_scroll_value = window.pageYOffset;
                if (this.header_state == '') {
                    this.header_state = 'interacted';
                }
                if (this.new_scroll_value < 1) {
                    this.has_scrolled_down = false;
                } else {
                    // Add an offset to the scroll
                    if (Math.abs(this.new_scroll_value - this.old_scroll_value) < 20) {
                        return;
                    }
                    if (this.old_scroll_value < this.new_scroll_value) {
                        this.has_scrolled_down = true;
                    } else {
                        this.has_scrolled_down = false;
                    }
                }
                this.old_scroll_value = this.new_scroll_value;
            },
            /**
             * Opens nav drawer and disables scroll
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
            openNavigationDrawer() {
                this.is_drawer_open = !this.is_drawer_open;
                if(this.drawer_state == '') {
                    this.drawer_state = 'interacted';
                }
                if(this.is_drawer_open) {
                    document.body.classList.add('u-disable-scroll');
                } else {
                    document.body.classList.remove('u-disable-scroll');
                }
            },
            /**
             * Closes nav drawer and enables scroll
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
            closeNavigationDrawer() {
                this.is_drawer_open = false;
                document.body.classList.remove('u-disable-scroll');
            },
            /**
             * Check if page get resized and remove navigation drawer
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
            checkOnResize() {
                let window_width = window.innerWidth;
                if(window_width > getBreakpoint('lg') && this.is_drawer_open) {
                    this.is_drawer_open = false;
                    document.body.classList.remove('u-disable-scroll');
                }
            }
        }
    }
</script>
