/**
 * Google Tag Manager
 * https://developers.google.com/tag-platform/tag-manager/web
 *
 * @author Robin van Leeuwen <robin@click.nl
 * @author Derk Jan Speelman <derkjan@click.nl>
 */
export const loadGtm = () => {

    // Add GTM script in head
    const script = document.createElement('script');
    script.innerText = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WT7LKQ7')";
    script.async = true;
    document.head.prepend(script);

    // Add GTM noscript in body
    const noscript = document.createElement('noscript');
    noscript.innerText = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WT7LKQ7" height="0" width="0" style="display:none; visibility:hidden"></iframe>';
    document.body.prepend(noscript);
}
