<template>
    <div class="c-alert c-alert--success c-alert--radius">
        <span>{{ modelValue }}</span>
    </div>
</template>
<script>
    export default {
        props: ['modelValue']
    }
</script>
