<template>
    <div v-if="(images && images.length > 0) || image_object" class="c-image" :class="is_with_text_content && 'c-image--margin'">
        <!-- Image slider | Multiple images -->
        <agile v-if="images && images.length > 0" :options="agile_options">
            <div v-for="image in images" class="c-image__slide" :key="image">
                <responsive-image
                    :class_name="'c-image__image'"
                    :is_background_image="false"
                    :small="image.image_small"
                    :medium="image.image_medium"
                    :large="image.image_large"
                    :alt_text="image.alt_text ? image.alt_text : title"
                ></responsive-image>
            </div>
        </agile>
        <!-- Single image -->
        <div v-else-if="image_object" class="c-image__slide">
            <responsive-image
                :class_name="'c-image__image'"
                :is_background_image="false"
                :small="image_object.image_small"
                :medium="image_object.image_medium"
                :large="image_object.image_large"
                :alt_text="image_object.alt_text ? image_object.alt_text : title"
            ></responsive-image>
        </div>
    </div>
</template>

<script>
    import { VueAgile } from 'vue-agile';
    import ResponsiveImage from './ResponsiveImage';

    export default {
        props: {
            title : String,
            images : Object,
            is_with_text_content : Boolean,
            image_object: Object,
        },
        components: {
            agile: VueAgile,
            'responsive-image' : ResponsiveImage
        },
        data() {
            return {
                agile_options: {
                    navButtons: false,
                    dots: false,
                    fade: true,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    speed: 2500
                }
            }
        },
    }
</script>