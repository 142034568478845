<template>
    <ul class="c-accordion o-list-unstyled">
        <template v-for="(item, key) in items" :key="key">
            <li v-if="item.title && item.description" class="c-accordion__item">
                <button  @click="openContent($event)" class="c-accordion__header o-flex o-flex--justify o-flex--middle">
                    <h2 class="c-accordion__title c-heading--xsmall">{{ item.title }}</h2>
                    <span class="c-accordion__icon far fa-chevron-down js-accordion-icon"></span>
                </button>
                <div v-html="item.description" class="c-accordion__content u-margin-vertical-small"></div>
            </li>
        </template>
    </ul>
</template>

<script>
    export default {
        props: {
            items : Object
        },
        methods: {
            /**
             * Opens/closes accordion content
             *
             * @author Lennart van Welzen <lennart@click.nl>
             * @return void
             */
            openContent(event) {
                let element = event.target.parentNode;
                let icon = element.getElementsByClassName('js-accordion-icon');
                if(element.classList.contains('c-accordion__item--active')) {
                    element.classList.remove('c-accordion__item--active');
                    icon[0].classList.add('fa-chevron-down');
                    icon[0].classList.remove('fa-chevron-up');
                } else {
                    element.classList.add('c-accordion__item--active');
                    icon[0].classList.remove('fa-chevron-down');
                    icon[0].classList.add('fa-chevron-up');
                }
            }
        }
    }
</script>