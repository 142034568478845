<template>
    <div class="o-col o-col--sm-12 o-col--lg-5">
        <panel :title="windowTitle" :is_form="true">
            <form v-if="!is_toggle_password_forget" class="c-form" v-on:submit.prevent="submitForm">
                <div class="c-form__group">
                    <label for="email">{{ translate('js.frontend.account.email') }}</label>
                    <input class="c-form__input" id="email" type="email" v-model="form.email" :placeholder="translate('js.db.placeholder.email')" :maxLength="256">
                    <p v-if="hasError('email')" class="c-form__message c-form__message--error">
                        {{ getError('email') }}
                    </p>
                </div>
                <div class="c-form__group">
                    <label for="password">{{ translate('js.frontend.account.password') }}</label>
                    <input class="c-form__input" id="password" type="password" autocomplete="on" v-model="form.password" :placeholder="translate('js.db.placeholder.password')">
                    <p v-if="hasError('password')" class="c-form__message c-form__message--error">
                        {{ getError('password') }}
                    </p>
                </div>
                <div class="c-form__button-group">
                    <div class="c-form__submit">
                        <button :disabled="disable_submit" class="c-button c-button--solid" type="submit">
                            {{ translate('js.frontend.account.login') }} <span class="c-button__icon far fa-arrow-right"></span>
                        </button>
                    </div>
                    <button class="c-button--clear" type="button" v-on:click.prevent="is_toggle_password_forget = !is_toggle_password_forget">
                        {{ translate('js.frontend.account.forgot_password_button')}}
                        <span class="c-button__icon far fa-arrow-right"></span>
                    </button>
                </div>
            </form>
            <forgot-password-form v-if="is_toggle_password_forget" v-on:close="toggleForgetPasswordForm()"></forgot-password-form>
        </panel>
    </div>
</template>

<script>
    import Panel                from '../Panel';
    import ForgotPasswordForm   from './ForgotPasswordForm';
    import { usePage }          from '@inertiajs/vue3';

    export default {
        data() {
            return {
                form: {},
                errors: null,
                is_toggle_password_forget: false,
                disable_submit: false
            }
        },
        emits: ['toggle-password-forget'],
        components: {
            'panel'                 : Panel,
            'forgot-password-form'  : ForgotPasswordForm,
        },
        watch : {
            is_toggle_password_forget : function(value) {
                this.$emit('toggle-password-forget', value);
            }
        },
        computed : {
            /**
             * Returns the right title for the window
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return  string
             */
            windowTitle: function() {
                if(this.is_toggle_password_forget){
                    return Lang.get('js.frontend.account.forgot_password');
                }
                return Lang.get('js.frontend.account.login_title');
            }
        },
        methods: {
            /**
             * Toggle password forget form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return
             */
            toggleForgetPasswordForm: function()
            {
                this.is_toggle_password_forget = false;
            },

            /**
             * Submit the form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm: function()
            {
                this.disable_submit = true;
                this.errors = null;
                axios.post(`/${usePage().props.locale}/api/login`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            /**
             * Submit form success
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitSuccess: function(response)
            {
                this.disable_submit = false;
                window.location.href = response.data.route;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(error)
            {
                this.disable_submit = false;
                if(error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.error_messages = Lang.get('frontend.js.global.422_error');
                } else {
                    this.error_messages = Lang.get('frontend.js.global.error');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                return this.errors[field][0];
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors[field] && (this.errors[field].length > 0);
            },
        }
    }
</script>
