import { createApp, h }             from 'vue';
import { createInertiaApp, router } from '@inertiajs/vue3';

import PageLayout                   from './Vue/Shared/PageLayout';
import CheckoutLayout               from './Vue/Shared/CheckoutLayout';
import ErrorLayout                  from './Vue/Shared/ErrorLayout';

import SectionCardOverview          from './Vue/Sections/section-card-overview';
import SectionHeroSlider            from './Vue/Sections/section-hero-slider';
import SectionImageTextContent      from './Vue/Sections/section-image-text-content';
import SectionFaq                   from './Vue/Sections/section-faq';
import SectionTextContent           from './Vue/Sections/section-text-content';
import SectionImage                 from './Vue/Sections/section-image';
import SectionTicketSelect          from './Vue/Sections/section-ticket-select';

import Mixin from './Vue/Mixins/Mixins';
import { loadGtm } from './gtm.js';

import VueCookies from 'vue-cookies';

var is_gtm_loaded = false;

// Inertia events
router.on('navigate', (event) => {
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            'url': event.detail.page.url,
            'event': 'pageview'
        });
    }
});

createInertiaApp({
    resolve: name => {
        let page = require(`./Vue/Pages/${name}`).default;
        // When the page is an error page, load the error layout
        if (Array.isArray(page.__file) && page.__file.includes('Pages/Error.vue')) {
            page.layout = ErrorLayout;
        } else {
            // When the page is NOT in the checkout flow, load the default Master layout
            if (!name.startsWith('Checkout')) {
                page.layout = PageLayout;
            // When the page is in the checkout flow, load the Checkout layout
            } else if (name.startsWith('Checkout')){
                page.layout = CheckoutLayout;
            }
        }

        // Check if environment is production before GTM will be loaded
        if (is_gtm_loaded === false && window.development !== true) {
            loadGtm();
            is_gtm_loaded = true;
        }

        return page;
    },
    setup({ el, App, props, plugin }) {
        el.classList.add('u-full-height', 'o-flex', 'o-flex--column')
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueCookies)

            .mixin(Mixin)
            .component('section-card-overview', SectionCardOverview)
            .component('section-hero-slider', SectionHeroSlider)
            .component('section-faq', SectionFaq)
            .component('section-image-text-content', SectionImageTextContent)
            .component('section-text-content', SectionTextContent)
            .component('section-image', SectionImage)
            .component('section-ticket-select', SectionTicketSelect)

            app.mount(el)
    },
});

window.axios = require('axios');
require('../../../vendor/click/clickadmin/src/resources/js/global/js-translation.js');
require('./components/breakpoints.js');
setBreakpoints();
